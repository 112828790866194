import request from '../utils/request'

// API-F76 - [GoodsController] goods/list 商品管理列表
export function getGoods (params) {
  return request({
    url: `/goods/list`,
    method: 'get',
    params
  })
}

// API-F77 - [GoodsController] goods/create 商品新增
export function addGoods (data) {
  return request({
    url: `/goods/create`,
    method: 'post',
    data
  })
}

// API-F78 - [GoodsController] goods/{goods_id}/detail 商品詳細
export function getGoodsDetail (goods_id, params) {
  return request({
    url: `/goods/${goods_id}/detail`,
    method: 'get',
    params
  })
}

// API-F79 - [GoodsController] goods/{goods_id}/edit 商品編輯
export function editGoods (goods_id, data) {
  return request({
    url: `/goods/${goods_id}/edit`,
    method: 'put',
    data
  })
}

// API-F80 - [GoodsController] goods/delete 商品刪除
export function deleteGoods (params) {
  return request({
    url: `/goods/delete`,
    method: 'delete',
    params
  })
}
