<template>
  <div class="col-lg-9 col-md-9 pb-5 mb-5">
    <AddGoods></AddGoods>
    <GoodsList></GoodsList>
  </div>
</template>
<script setup>
import GoodsList from './Components/GoodsList'
import AddGoods from './Components/AddGoods'

</script>